const DocMenuConfig = [
  {
    pages: [
      {
        heading: "main",
        route: "/",
      },
      {
        heading: "mods",
        route: "/mods",
      },
      {
        heading: "author",
        route: "/author/14",
        grant: "ADMIN",
      },
      {
        heading: "mod",
        route: "/mod/55",
        grant: "ADMIN",
      },
      {
        heading: "request-mod",
        route: "/request-mod",
      },
    ],
  },
  {
    heading: "administering",
    svgIcon: "media/icons/duotune/art/art002.svg",
    fontIcon: "bi-app-indicator",
    grant: "ADMIN",
    pages: [
      {
        heading: "admin-dashboard",
        route: "/admin-dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person-workspace",
      },
      {
        heading: "authors",
        route: "/admin-dashboard/authors",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-people",
      },
      {
        heading: "menu-mods",
        route: "/admin-dashboard/mods",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-box",
      },
      {
        heading: "tags",
        route: "/admin-dashboard/tags",
        svgIcon: "media/icons/duotune/text/txt001.svg",
        fontIcon: "bi-bookmark",
      },
      {
        heading: "articles",
        route: "/admin-dashboard/articles",
        svgIcon: "media/icons/duotune/text/txt001.svg",
        fontIcon: "bi-newspaper",
      },
      {
        heading: "mod-requests",
        route: "/admin-dashboard/mod-requests",
        svgIcon: "media/icons/duotune/text/txt001.svg",
        fontIcon: "bi-envelope",
      },
      {
        heading: "mod-translations",
        route: "/admin-dashboard/mod-translations",
        svgIcon: "media/icons/duotune/text/txt001.svg",
        fontIcon: "bi-envelope-exclamation-fill",
      },
    ],
  },
];

export default DocMenuConfig;

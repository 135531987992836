import { computed } from "vue";
import store from "@/store/index";
/* eslint-disable  @typescript-eslint/no-explicit-any */

/**
 * Return name of the theme
 * @returns {string}
 */
export const themeName = computed(() => {
  return store.getters.layoutConfig("themeName");
});

/**
 * Return version of the theme
 * @returns {string}
 */
export const version = computed(() => {
  return store.getters.layoutConfig("themeVersion");
});

/**
 * Return demo name
 * @returns {string}
 */
export const demo = computed(() => {
  return store.getters.layoutConfig("demo");
});

//code copy button initialization
export const useCopyClipboard = () => {
  const _init = (element) => {
    let elements = element;

    if (typeof elements === "undefined") {
      elements = document.querySelectorAll(".highlight");
    }

    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; ++i) {
        const highlight = elements[i];
        const copy = highlight.querySelector(".highlight-copy");
      }
    }
  };

  return {
    init: (element?) => {
      _init(element);
    },
  };
};

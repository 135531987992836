
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import {Mutations} from "@/store/enums/StoreEnums";
import store from "@/store";
import DarkLayoutConfig from "@/core/config/DarkLayoutConfig";
import {config} from "@/core/helpers/config";

export default defineComponent({
  name: "topbar",

  components: {},

  setup() {
    const i18n = useI18n();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "/media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
      ru: {
        flag: "/media/flags/russia.svg",
        name: "Russian",
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
    };
  },
});

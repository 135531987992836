import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "aside-logo flex-column-auto",
  id: "kt_aside_logo"
}
const _hoisted_2 = {
  key: 0,
  href: "#"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  href: "#"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  id: "kt_aside_toggle",
  class: "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
  "data-kt-toggle": "true",
  "data-kt-toggle-state": "active",
  "data-kt-toggle-target": "body",
  "data-kt-toggle-name": "aside-minimize"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1 rotate-180" }
const _hoisted_8 = { class: "aside-menu flex-column-fluid" }
const _hoisted_9 = {
  class: "aside-footer flex-column-auto pt-5 pb-7 px-5",
  id: "kt_aside_footer"
}
const _hoisted_10 = {
  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/doc-overview",
  class: "btn btn-custom btn-primary w-100",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-delay-show": "8000",
  title: "Check out the complete documentation with over 100 components"
}
const _hoisted_11 = { class: "btn-label" }
const _hoisted_12 = { class: "svg-icon btn-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_aside",
    class: _normalizeClass(["aside aside-hoverable", [
      _ctx.asideTheme === 'light' && 'aside-light',
      _ctx.asideTheme === 'dark' && 'aside-dark',
    ]]),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "start",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideTheme === 'dark')
        ? (_openBlock(), _createElementBlock("a", _hoisted_2, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.darkLogo,
              class: "h-25px logo"
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      (_ctx.asideTheme === 'light')
        ? (_openBlock(), _createElementBlock("a", _hoisted_4, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.lightLogo,
              class: "h-25px logo"
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr080.svg" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_KTMenu)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("a", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t("docsAndComponents")), 1),
        _createElementVNode("span", _hoisted_12, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen005.svg" })
        ])
      ])
    ])
  ], 2))
}